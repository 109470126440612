<template>
  <div>
     <!-- 技术需求对接专项 -->
    <DockingNei></DockingNei>
  </div>
</template>

<script>
 import DockingNei from "./base/DockingNei";
export default {
  name: "Expert",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
       this.ConList = item;
    },
  },
  components: {
     DockingNei,
  },
};
</script>

<style scoped>
</style>
