<template>
  <div class="containers">
    <div class="system-head w">
      <div class="head-left">
        <a href="" class="logo"><img :src="urlImg + logo" /></a>
      </div>
      <div class="head-center">
        <!-- 技术转移全流程信息系统 -->
        <span class="head-topic">需求发布全流程信息系统11 </span>
      </div>
    </div>

    <div class="all_imglist">
      <div class="w">
        <div class="card-item">
          <div class="pea-title">
            <div class="one-content">
              <div class="copy-title">
                <div class="copy-con copy-con1">
                  <span></span>
                  <h3>我创建的需求</h3>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <ul class="cur_list clearfix">
            <li class="img-item" @click="fa_bu">
              <div class="inner-boxing">
                <div class="show-title">
                  <h2 class="subtitle-item">点击查看需求解决过程</h2>
                </div>
                <img src="../../../assets/img/qiyeyun/xq.jpg" alt="" />
              </div>
            </li>
            <li class="img-item" @click="need">
              <div class="inner_icon">
                <div class="inner_cos">
                  <i class="el-icon-circle-plus"></i>
                  <p>创建需求</p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="card-item card-item1">
          <div class="pea-title">
            <div class="one-content">
              <div class="copy-title">
                <div class="copy-con copy-con1">
                  <span></span>
                  <h3>成功案例</h3>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <ul class="cur_list clearfix">
            <li
              class="img-item"
              v-for="(item, index) in success"
              :key="index"
              @click="an_li(item)"
            >
              <div class="inner-boxing">
                <div class="show-title1">
                  <!-- <img :src="urlImg + item.img" alt="" /> -->
                  <img v-if="item.img" :src="urlImg + item.img" alt="" />
                  <img
                    v-else
                    src="../../../assets/img/huiyuanzhongxin/default_error.png"
                    alt=""
                  />
                  <h2 class="subtitle-item">
                    {{ item.title }}
                  </h2>
                  <div class="cur_status">
                    <span class="service">已结束(成功)</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { demandSuccess, Logo } from "@/common/js/api";
import { urlImg } from "@/common/js/url";
export default {
  name: "DockingNei",
  data() {
    return {
      success: [
        // {
        //   img: require("../../../assets/img/qiyeyun/xq.png"),
        //   title: "案例名称案例名称案例名称案例名称案例",
        //   end: "已结束(成功)",
        // },
       ],
      logo: "",
      urlImg: "",
    };
  },
  created() {
    this.getLogo();
    this.Success();
    this.urlImg = urlImg();
  },
  methods: {
    // 获取logo
    getLogo() {
      Logo({})
        .then((res) => {
          this.logo = res.data.logo;
        })
        .catch(() => {});
    },
    // 成功案例
    Success() {
      demandSuccess({ page: 1, limits: 4 }).then((res) => {
         this.success = res.data.data;
      });
    },
    fa_bu() {
      this.$router.push({
        path: "/needjc",
      });
    },
    need() {
      this.$router.push({
        path: "/needfb",
      });
    },
    an_li(item) {
      this.$router.push({
        path: "/needcgal",
        query: {
          id: item.id,
        },
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
.containers {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  height: 100px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  .system-head {
    position: relative;
    .head-left {
      position: absolute;
      left: 0;
      margin-top: 23px;
      width: 169px;
      height: 54px;
    }
    .head-center {
      text-align: center;
      color: #101010;
      font-size: 28px;
      .head-topic {
        vertical-align: top;
        line-height: 100px;
      }
    }
  }
  .all_imglist {
    margin-top: 6px;
    background-color: #f8f9fa;
    height: 100vh;
    .cur_list .img-item {
      float: left;
      width: 300px;
      height: 180px;
      background: #fff;
      cursor: pointer;
      position: relative;
      margin-right: 29px;
      border-radius: 4px;
      .inner-boxing {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;
        .show-title {
          position: absolute;
          left: 0;
          top: 0;
          color: #fff;
          font-size: 20px;
          padding: 75px 50px;
          width: 100%;
          height: 100%;
          -webkit-box-sizing: border-box;
          z-index: 1;
          box-sizing: border-box;
        }
        img {
          display: block;
          width: 100%;
          height: 180px;
        }
      }
      .inner_icon {
        margin: 29px 23px;
        height: 122px;
        border: 1px dotted #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        .inner_cos {
          font-size: 16px;
          color: #b1afaf;
          text-align: center;
          i {
            font-size: 42px;
            margin-bottom: 14px;
          }
        }
      }
    }
    .card-item1 .cur_list .img-item:nth-of-type(4n) {
      margin-right: 0;
    }
    .card-item1 .cur_list .img-item {
      width: 253px;
      height: 257px;
      margin-right: 61px;
      margin-bottom: 10px;
      .subtitle-item {
        font-size: 18px;
        padding: 10px;
        color: #00050a;
        line-height: 1.1;
        white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      }
      .cur_status {
        width: 100%;
        box-sizing: border-box;
        color: #3892eb;
        text-align: right;
        padding-right: 10px;
        .service {
          width: 92px;
          height: 24px;
          line-height: 24px;
          border: 1px solid #3892eb;
          border-radius: 3px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.containers{
		height: 6rem;
	}
	.containers .system-head .head-left{
		width: 12rem;
		height: 4rem;
		margin-top: 1rem;
	}
	.containers .system-head .head-center{
		font-size: 2.2rem;
		font-weight: bold;
		padding-left: 2rem;
	}
	.copy-con h3{
		font-size: 2rem;
	}
	.copy-con{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.containers .all_imglist .cur_list .img-item{
		width: 48% !important;
		height: auto !important;
		margin: 0 0 2rem 0 !important;
	}
	.containers .all_imglist .cur_list .img-item .inner-boxing img{
		height: 15rem;
	}
	.cur_list{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.show-title{
		top: 16% !important;
	}
	.show-title h2{
		font-size: 1.8rem;
	}
	.containers .all_imglist .cur_list .img-item .inner_icon{
		height: 12rem;
	}
	.containers .all_imglist .cur_list .img-item .inner_icon .inner_cos i{
		font-size: 6rem;
	}
	.containers .all_imglist .cur_list .img-item .inner_icon .inner_cos{
		font-size: 1.8rem;
	}
	.containers .all_imglist .card-item1 .cur_list .img-item .subtitle-item{
		font-size: 1.8rem;
		padding: 1rem 0;
	}
	.containers .all_imglist .card-item1 .cur_list .img-item .cur_status .service{
		font-size: 1.6rem;
		margin: 1rem 0;
	}
}
</style>
